<!--会员用户管理端布局-->
<template>
  <div class="com-page">
    <el-container>
      <el-header>
        <div>
          <a href="/"><img src="@/assets/images/logo.jpg" /></a>
        </div>
        <div class="head_txt">
          <span v-if="!isUserLogin">       
            <router-link to="/login" >登录</router-link>
            |
            <router-link to="/regist">注册</router-link>
          </span>
          <span v-else>
            欢迎企业用户，<span class="blue">{{uname}}</span> 光临！ |
            <router-link to="/">返回主页</router-link>
            |          
            <a @click="logout" class="logout">安全退出</a>
          </span>
        </div>
      </el-header>
      <el-container id='elmain'>
        <el-aside :class="[isCollapse ? 'menu-collapse-width' : 'menu-expand-width']">
          <div class="collapseIcon">
            <span class="arrow" @click="changeCollapse()">
              <el-icon><DArrowRight v-if="isCollapse" /><DArrowLeft v-else /></el-icon>
            </span>
            <span class="arrow-text">导航菜单</span>
          </div>
          <el-menu v-loading="loading" :default-active="$route.path" unique-opened :collapse="isCollapse" >            
            <!-- 循环数据格式 -->
            <template v-for="(menu,index) in menuData" :key="index" >
              <el-sub-menu v-if="menu.menuItem" :index="`${menu.id}`">
                <template #title>
                  <component :is="menu.icon" class="icon" ></component>
                  <span>{{menu.name}}</span>
                </template>
                <div v-for="item1 in menu.menuItem" :key="item1.index">
                  <el-sub-menu v-if="item1.menuItem.length > 0" :index="`${item1.id}`">
                    <template #title>{{item1.name}}</template>
                    <div v-for="item2 in item1.menuItem" :key="item2.index">
                      <el-sub-menu v-if="item2.menuItem.length > 0" :index="`${item2.id}`">
                        <template #title>{{item2.name}}</template>
                        <el-menu-item :index="item3.path" v-for="item3 in item2.menuItem" :key="item3.index" @click="menuClick(item3)">{{item3.name}}</el-menu-item>
                      </el-sub-menu>
                      <el-menu-item v-else :index="item2.path" @click="menuClick(item2)">{{item2.name}}</el-menu-item>
                    </div>
                  </el-sub-menu>
                  <el-menu-item v-else :index="item1.path" @click="menuClick(item1)">{{item1.name}}</el-menu-item>
                </div>
              </el-sub-menu>
              <el-menu-item v-else :index="menu.id" @click="menuClick(menu)">
                <component :is="menu.icon" class="icon" ></component>
                <template #title>{{menu.name}}</template>
              </el-menu-item>
            </template>
          </el-menu>          
        </el-aside>
        <el-main>
          <!-- <el-tabs v-model="activeTab"
            type="card" @tab-remove="removeTab"
            @tab-click="tabClick">
            <el-tab-pane
              v-for="item in tabsItem" :key="item.name" :label="item.label"
              :name="item.name" :closable="item.closable">
            </el-tab-pane>
          </el-tabs> -->
          <!-- <router-view v-slot="{ Component }">
            <keep-alive :include="includePages">
              <component :clientHeight="clientHeight" :is="Component" :key="$route.Path" v-if="$route.meta.keepAlive" />
            </keep-alive>
            <component :is="Component" v-if="!$route.meta.keepAlive" />
          </router-view> -->
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>  
</template>

<script>
// import HeaderTop from '@/components/headerTop'
// 引入样式文件
import '@/assets/css/member.css'
export default {
  name: 'Home',
  // components: {
  //   HeaderTop   
  // },
  data () {    
    return {
      // 是否登录 
      isUserLogin: false,
      // 当前用户名
      uname: '',
      // clientHeight: document.body.clientHeight,
      isCollapse: false,
      loading: false,
      // includePages: [],
      menuData: [
        {
          id: '100',
          name: '欢迎',
          path: '/member',
          icon: 'HomeFilled'
        },
        {
          id: '200',
          name: '用户信息',
          icon: 'UserFilled',
          menuItem: [{
            id: '201',
            name: '完善用户信息',
            path: '/member/useredit',
            menuItem:[]
          },{
            id: '202',
            name: '修改密码',
            path: '/member/pwdedit',
            menuItem:[]
          }]
        }, {
          id: '300',
          name: '订单管理',   
          icon: 'List', 
          menuItem: [{
            id: '301',
            name: '订单信息',
            path: '/member/order',
            menuItem:[]
          }, {
            id: '303',
            name: '二级 2-2',
            path: '',
            menuItem:[]
          }]
        }, {
          id: '400',
          name: '一级 2',          
          menuItem: [{
            id: '401',
            name: '二级 2-1',
            path: '',
            menuItem:[]
          }, {
            id: '402',
            name: '二级 2-2',
            path: '',
            menuItem:[]
          }]
        }
      ]
    }
  },
  mounted () {
    this.getMainHeight()
    // 监听window窗口大小变化
    window.addEventListener('resize', this.getMainHeight)
    // 获取本地信息 用户登录成功后取用户名
    const ui = this.$api.ui
    if (ui == null) {
      this.isUserLogin = false
      this.uname = ''
    } else {
      this.isUserLogin = true
      this.uname = ui.uname      
    }

    console.log('document.body.clientHeight=', document.body.clientHeight)
    // this.getMainHeight()
    // // 监听window窗口大小变化
    // window.addEventListener('resize', this.getMainHeight)
    // this.getUserInfo()
    // this.menuClick()
    // this.getData()
  },
  unmount () {
    window.removeEventListener('resize', this.getMainHeight)
  },
  methods: { 
    // main高 = 网页可见区域高 - el-header高
    getMainHeight () {
      if (window.innerHeight) {
        this.clientHeight = window.innerHeight - 100
      } else if ((document.body) && (document.body.clientHeight)) {
        this.clientHeight = document.body.clientHeight - 100
      }
      document.getElementById('elmain').style.height = this.clientHeight + 'px'
    },   
    changeCollapse () {
      this.isCollapse = !this.isCollapse
    },
    menuClick(item) {
      // console.log('item=', item)
      this.$router.push({ path: item.path })
    },
    async logout () {
      this.$confirm('您确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          //background: 'rgba(0, 0, 0, 0.7)'
        })
        const { data: ret } = await this.$http.post(this.$api.logout)
        console.log('ret=', ret)
        if (ret.code === 1) {
          this.isUserLogin = false
          this.uname = ''
          this.$message.success(ret.msg)
          this.$storage.remove('dxkjy-webui')
          this.$router.replace('/')
        } else {
          this.$message.error(ret.msg)
        }
        loading.close()
      })
    }
  }
}
</script>

<style scoped>
.el-container {
  height: 100%;
}
.el-header {
  /* background-color: #409EFF; */
  /* color: var(--el-text-color-primary); */
  background-color: #FFF;
  height: 100px;
  line-height: 100px;
  display: flex;
  justify-content: space-between;  
  border-bottom: 1px solid #606266;
}
.el-header img {
  height: 95px;
}

.el-aside {
  background-color: #d3dce6;
  color: #303133;
  /* width: 200px;
  text-align: center; */
}
.menu-expand-width {
  width: 200px;
}
.menu-collapse-width {
  width: 50px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  
.collapseIcon{
  background-color: #606266;
  color: #FFFFFF;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;  
}
.collapseIcon .arrow {
  padding: 0 8px 0 22px;
  cursor: pointer;
  vertical-align:middle;
}

.el-menu {
  background-color: #d3dce6;
}

.el-main {
  padding: 4px 5px;
  background-color: #fff;
  /* border: 1px solid @Danger;
  height: 95%; */
  
}
/* .userinfo {
  float: right;
  text-align: right;
  span {
    color: @White;
    cursor: pointer;
  }
  i {
     font-size:20px;
  }
  .el-icon-arrow-down {
    font-size: 14px;
    color: #ddd;
  }
} */
</style>
